import React from "react";
import "./about.css";
import about from "../../img/about.png";
export default function About() {
  return (
    <>
      <span className="anchor" id="About"></span>
      <div className="about">
        <div className="about_container">
          <div className="about_right">
            <img src={about} alt="" />
          </div>
          <div className="about_left">
            <h2 data-aos="fade-right" data-aos-duration="1000">
              About me
            </h2>
            <p data-aos="fade-up" data-aos-duration="1000">
              I'm Ajith. I love connecting with people and learning. I am a
              fulltime freelancer
            </p>
            <p data-aos="fade-up" data-aos-duration="1000">
              I'm a digital product designer focusing on crafting user
              experiences and design systems for software, web, and mobile
              products.
            </p>
            <p data-aos="fade-up" data-aos-duration="1000">
              Contact me for freelance/contract work, consulting, or if you want
              to chat! I'd be happy to hear from you.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
