import React from "react";
import { ReactComponent as React_logo } from "../../img/skills/react.svg";
import { ReactComponent as Js_logo } from "../../img/skills/js.svg";
import { ReactComponent as Css_logo } from "../../img/skills/css.svg";
import { ReactComponent as Node_logo } from "../../img/skills/nodejs.svg";
import { ReactComponent as Mongo_logo } from "../../img/skills/mongodb.svg";
import "./Skills.css";
export default function Skills() {
  return (
    <section>
      <div className="border"></div>
      <div className="icon_box" data-aos="zoom-in" data-aos-duration="1000">
        <React_logo className="skill_icons"></React_logo>
        <Js_logo className="skill_icons"></Js_logo>

        <Css_logo className="skill_icons"></Css_logo>

        <Node_logo className="skill_icons"></Node_logo>

        <Mongo_logo className="skill_icons"></Mongo_logo>
      </div>
      {/* <img src={react} alt="" className="skill_icons" />

      <img src={react} alt="" className="skill_icons" />

      <img src={react} alt="" className="skill_icons" />

      <img src={react} alt="" className="skill_icons" />

      <img src={react} alt="" className="skill_icons" />

      <img src={react} alt="" className="skill_icons" />

      <img src={react} alt="" className="skill_icons" /> */}
      <div className="border"></div>
    </section>
  );
}
