import React from "react";
import "./footer.css";
export default function Footer() {
  return (
    <div className="footer">
      <div className="footer_box">
        <p> Ajith.</p>
        <ul>
          <li>
            <a href="#">Service</a>
          </li>
          <li>
            <a href="#">Portfolio</a>
          </li>
          <li>
            <a href="#">Blog</a>
          </li>
          <li>
            <a href="#">Testimonial</a>
          </li>
          <li>
            <a href="#">About</a>
          </li>
          <li>
            <a href="#">Contact</a>
          </li>
          <li>
            <a href="#"> Allright reserved © 2022</a>
          </li>
        </ul>
      </div>
    </div>
  );
}
