import React from "react";
import "./Header.css";
import web from "../img/developer/19362653.png";
// import AOS from "aos";
// import "aos/dist/aos.css"; // You can also use <link> for styles
// // ..
// AOS.init();
export default function Header() {
  return (
    <section>
      <header className="header">
        <div className="box">
          <div className="left" data-aos="fade-right" data-aos-duration="1000">
            <h1>Hello, I’m Ajith</h1>
            <h2> Web Developer | Freelancer | UI UX Designer</h2>
            <p>
              I'm a digital product designer focusing on crafting user
              experiences and design systems for software, web, and mobile
              products.
            </p>
            <button className="btn">
              <a href="#Contact">Contact me</a>
            </button>
          </div>

          <div className="right">
            <img src={web} alt="developer_Illustration" className="web_dev" />
          </div>
        </div>
      </header>
    </section>
  );
}
