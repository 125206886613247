import React from "react";
import "./service.css";
import icon1 from "../../img/icons/icon-1.png";
import icon2 from "../../img/icons/icon-2.png";
import icon3 from "../../img/icons/icon-3.png";
import icon4 from "../../img/icons/icon-4.png";
import icon5 from "../../img/icons/icon-5.png";
import icon6 from "../../img/icons/icon-6.png";

export default function Service() {
  return (
    <>
      <span className="anchor" id="service"></span>
      <div className="service">
        <h2 data-aos="fade-right" data-aos-duration="1000">
          Services that connect <br></br> you to your users
        </h2>
        <div className="contain">
          <div className="box-1" data-aos="fade-up" data-aos-duration="1000">
            <img src={icon1} alt="icon1" />
            <h3>Mobile Application</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat
              nibh tristique ipsum.
            </p>
          </div>

          <div className="box-2" data-aos="fade-up" data-aos-duration="1000">
            <img src={icon2} alt="icon2" />
            <h3>Web Application</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat
              nibh tristique ipsum.
            </p>
          </div>

          <div className="box-3" data-aos="fade-up" data-aos-duration="1000">
            <img src={icon3} alt="icon3" />
            <h3>User Interface Design</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat
              nibh tristique ipsum.
            </p>
          </div>

          <div className="box-4" data-aos="fade-up" data-aos-duration="1000">
            <img src={icon4} alt="icon4" />
            <h3>Project Management</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat
              nibh tristique ipsum.
            </p>
          </div>

          <div className="box-5" data-aos="fade-up" data-aos-duration="1000">
            <img src={icon5} alt="icon5" />
            <h3>Strategy and Branding</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat
              nibh tristique ipsum.
            </p>
          </div>

          <div className="box-6" data-aos="fade-up" data-aos-duration="1000">
            <img src={icon6} alt="icon6" />
            <h3>Product Designing</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat
              nibh tristique ipsum.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
