import { useEffect } from "react";
import About from "./components/about/About";
import Client from "./components/client/Client";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import Service from "./components/services/Service";
import Skills from "./components/skills/Skills";
import Works from "./components/works/Works";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

function App() {

  useEffect(() => {
    
    const showOnPx = 100;
    const backToTopButton = document.querySelector(".back-to-top");
    const pageProgressBar = document.querySelector(".progress-bar");
    var rootElement = document.documentElement
    
    const scrollContainer = () => {
      return document.documentElement || document.body;
    };
    
    const goToTop = () => {
      
      rootElement.scrollTo({
        top: 0,
        behavior: "smooth"
      })
      // document.body.scrollTop = 0;
      // document.documentElement.scrollTop = 0; 
    
    };
    
    document.addEventListener("scroll", () => {
      console.log("Scroll Height: ", scrollContainer().scrollHeight);
   
    
      const scrolledPercentage =
        (scrollContainer().scrollTop /
          (scrollContainer().scrollHeight - scrollContainer().clientHeight)) *
        100;
    
      pageProgressBar.style.width = `${scrolledPercentage}%`;
    
      if (scrollContainer().scrollTop > showOnPx) {
        backToTopButton.classList.remove("hidden");
      } else {
        backToTopButton.classList.add("hidden");
      }
    });
    
    backToTopButton.addEventListener("click", goToTop);
    
  }, [])
  

  

  return (
    <>
    <Navbar></Navbar>
    <Header></Header>
    <Skills></Skills>
    <Service></Service>
    <Works></Works>
    <Client></Client>
    <About></About>
    <Contact></Contact>
    <Footer></Footer>
    <div class="progress-bar" />
<button class="back-to-top hidden">
  <svg xmlns="http://www.w3.org/2000/svg" class="back-to-top-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 11l5-5m0 0l5 5m-5-5v12" />
  </svg>
</button>

    </>
  );
}

export default App;
